.button {
    font-weight: 300;
    font-family: Poppins,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    border-radius: 24px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: none;
    padding: .75rem 1.25rem;
    font-size: .875rem;
    line-height: 1.125;
    transition: all .25s cubic-bezier(.27,.01,.38,1.06);
    box-shadow: none;
    filter: drop-shadow(-2px -3px 9px rgba(123, 123, 123, 0.25));
    background-color: var(--secondary-color);
    color: var(--secondary-text-color);
}

button:disabled,
button[disabled]{
    filter: none;
    background-color: var(--disabled-background-color);
    color: var(--disabled-text-color);
}
