:root {
  --surface-color: #FBFBFB;
  --background-color: #FFFFFF;
  --disabled-background-color: #F1F1F1;
  --text-color: #000000;
  --secondary-text-color: #FFFFFF;
  --disabled-text-color: #C1C1C1;
  --secondary-color: #0D2687;
  --success-color: #29BB89;
  --on-success-color: #FFFFFF;
}

html,
body, #root {
  background-color: var(--surface-color);
  height: 100vh;
  width: 100vw;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
