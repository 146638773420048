.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginButton {
    border-radius: 50px;
    font-weight: 300;
    font-family: Poppins,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    border: 1px solid darkblue;
    padding: .75rem 1.25rem;
    font-size: .875rem;
    line-height: 1.125;
    transition: all .25s cubic-bezier(.27,.01,.38,1.06);
    color: #fff;
    background-color: darkblue;
    box-shadow: none;
}