.carousel-control-next-icon,
.carousel-control-prev-icon {
  position: relative;
}

.carousel-control-next-icon::before,
.carousel-control-prev-icon::before {
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: block;
  padding: 1.25rem;
  border-radius: 5rem;
  background-color: #999;
  z-index: -1;
  opacity: 0.9;
}
