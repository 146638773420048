.SyncSideView {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 20px;
  background: white;
  border-radius: 6px;
  width: 0;
  min-width: 100%;
  word-wrap: break-word;
}

.SyncSideViewButtonContainer {
  display: flex;
  flex-direction: column;
}

.SyncSideViewButtonContainer > button {
  margin-bottom: 1rem;
}

.SyncSideViewSelectContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.SyncSideViewSelectContainer > select {
}

.SyncSideViewSelectBlock {
  margin-bottom: 1rem;
}
