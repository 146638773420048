.container {
    display: flex;
    flex-direction: column;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.buttonContainer > :first-child {
    margin-right: 20px;
}

.editorContainer {
    display: flex;
    filter: drop-shadow(-2px -3px 9px rgba(123, 123, 123, 0.25));
    margin-right: 20px;
    margin-left: 20px;
}

.searchViewContainer {
    display: flex;
    flex-direction: column;
    padding: 40px;
    background: #fafafa;
    margin-left: 20px;
}

.searchViewContainer > * {
    margin-bottom: 20px;
}

.tabContainer {
    margin-left: 20px;
}

.tab {
    font-weight: 300;
    font-family: Poppins,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    border-radius: 5px 5px 0 0;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: none;
    padding: .75rem 1.25rem;
    font-size: .875rem;
    line-height: 1.125;
    transition: all .25s cubic-bezier(.27,.01,.38,1.06);
    color: var(--disabled-text-color);
    box-shadow: none;
    filter: drop-shadow(-2px -3px 9px rgba(123, 123, 123, 0.25));
    background-color: var(--disabled-background-color);
}

.active {
    color: var(--secondary-text-color);
    background-color: var(--secondary-color);
}

.unsavedChangesAlert {
    border: 1px solid #ffeeba;
    background: #fff3cd;
    text-align: center;
    margin-bottom: 20px;
    color: #856404;
    display: table;
    border-radius: 5px;
    align-self: center;
    padding: 10px;
}
