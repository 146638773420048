.dashboardLinkSideView {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 20px;
  background: white;
  border-radius: 6px;
  width: 0;
  min-width: 100%;
  word-wrap: break-word;
}

.dashboardLinkContainer {
  display: flex;
  flex-direction: column;
}

.input {
  height: auto;
  padding: 0.5rem 1rem;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #becad6;
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: 0.375rem;
  box-shadow: none;
  margin-bottom: 20px;
  transition: box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.warning {
  color: #a31515;
  margin-bottom: 20px;
}

.underline {
  text-decoration: underline;
}