.buttonsContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 0px 30px;
}
.tabContainer {
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.tab {
  font-weight: 300;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  border-radius: 5px 5px 0px 0px;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: none;
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.125;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
  color: var(--disabled-text-color);
  background-color: var(--disabled-background-color);
  box-shadow: none;
}

.button {
  font-weight: 300;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.125;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
  color: var(--text-color);
  background-color: var(--background-color);
  box-shadow: none;
}

.button:nth-child(1) {
  margin-right: 10px;
}

.button:hover {
  background: var(--secondary-color);
  color: var(--secondary-text-color);
}

.active {
  color: #fff;
  background-color: var(--secondary-color);
}

.homepageModule {
  list-style-type: none;
  border-radius: 5px;
  border: 1px solid gray;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034);
  margin-top: 10px;
  margin-right: 30px;
  background: #fafafa;
  padding: 10px;
  cursor: move;
  display: flex;
  flex-direction: column;
  width: 70vw;
}

.homepageModuleContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.expired {
  background: darkred;
  color: white;
  border: 0;
}

.starting {
  margin-top: 5px;
  background: #17c671;
  color: white;
  border: 0;
}

.expiredTag {
  margin-left: 10px;
  background: white;
  border-radius: 50px;
  padding: 2px 10px;
  color: darkred;
}

.homepageModuleChangedLabel {
  margin-left: 5px;
  color: blue;
}

.homepageModule:first-child {
  margin-top: 0;
}
