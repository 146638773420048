.searchSideView {
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 20px;
    background: white;
    border-radius: 6px;
    width: 0;
    min-width: 100%;
    word-wrap: break-word;
}
.input {
    height: auto;
    padding: .5rem 1rem;
    font-size: .95rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #becad6;
    font-weight: 300;
    will-change: border-color,box-shadow;
    border-radius: .375rem;
    box-shadow: none;
    transition: box-shadow .25s cubic-bezier(.27,.01,.38,1.06),border .25s cubic-bezier(.27,.01,.38,1.06);
}

.searchSideView > :nth-child(1) {
    margin-bottom: 20px;
}
