.resultContainer {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    background-color: var(--success-color);
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    filter: drop-shadow(-2px -3px 9px rgba(123, 123, 123, 0.25));
}

.resultText {
    overflow-x: scroll;
    margin-left: 15px;
    color: var(--on-success-color);
}

.clipboardButton {
    background-color: unset;
    border: unset;
    padding: 15px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 50%;
    color: var(--on-success-color);
}

.clipboardButton:hover {
    background-color: #289672;
}

.clipboardButton:active {
    background-color: #1E6F5C;
}

